<template>
    <div class="main">
        <div id="container" class="container"></div>
    </div>
</template>
<script>
import axios from "axios";
import AMapLoader from '@amap/amap-jsapi-loader';
import { Message } from "element-ui";
import { exit } from "process";

export default {
    props: ['address'],
    data() {
        return {
            key: 'b8df6b7cf120cd220f51cea961c82fc9',
            // map:null,
            // longitude: 119.20058,//经度
            // latitude: 32.98972,//纬度
            location: [],//经纬度
        }
    },
    mounted() {

    },
    watch: {
        address() {
            this.GeoCode()
            this.initMap()
        }
    },
    methods: {
        // 将地址转为经纬度
        GeoCode() {

            let key = '9bfbad6c8d1ec326516b7479fa766fe4';//key
            //    axios.get(`https://restapi.amap.com/v3/geocode/geo?key=${key}&address=${address}`)
            fetch(`https://restapi.amap.com/v3/geocode/geo?key=${key}&address=${this.address}`, {
                method: 'get'
            }).then(res => res.json()).then(YES => {
                if (YES.info === 'OK') {
                    this.location = YES.geocodes[0].location.split(',')
                    // console.log();
                    // location
                } else {
                    Message.error('地址获取失败')
                }
            })
        },
        initMap() {
            // 密匙
            window._AMapSecurityConfig = {
                securityJsCode: '75d481467370e0add6b6871c03a0271d'//密匙
            }
            AMapLoader.load({
                key: 'b8df6b7cf120cd220f51cea961c82fc9',//key值
                version: '2.0',
                plugins: [''],
                AMapUI: {
                    version: '1.1',
                    plugins: []
                },
                // Loca:{
                //     version:'2.0.0'
                // }
            }).then(AMap => {

                console.log(AMap, '成功');

                let map = new AMap.Map('container', {//初始化地图
                    viewMode: '2D',//3D、2D
                    zoom: 15,//缩放大小
                    center: this.location,//初始化地图中心位置
                    mapStyle: 'amap://styles/7a1216fde2018403498b00456bca3d22'//样式ID
                })
                // 添加点标记
                var marker = new AMap.Marker({
                    position: this.location,   // 经纬度对象，也可以是经纬度构成的一维数组
                    title:'公司信息'
                });
                map.add(marker);

            }).catch(err => {
                console.log(err, '失败');
            })
        }
    }
}

</script>
<style>
.container {
    width: 50%;
    min-width: 40em;
    height: 100%;
    background-color: #f2f2f2;
}
</style>