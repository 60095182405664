<template>
    <div class="content">
        <div class="header">
            <div class="data-title">
                <div class="cot"></div>
                <h3>联系客服</h3>
            </div>
            <User></User>
        </div>
        <div class="cont-box">
            <div class="title">
                <div class="title-head">
                    <div class="title-header-item"></div>
                    <h3>联系我们</h3>
                    <div class="title-header-item"></div>
                </div>
                <p>Contact us</p>
            </div>
            <p class="cont-box-p">{{data.comintro}}</p>
            <div class="mapBox">
                <!-- 地图 -->
                <AMap style="Amap" :address="data.comadress"></AMap>
                <!-- 简介 -->
                <div class="mapText">
                    <h4>{{data.comname}}</h4>
                    <div class="mapText-cont">
                        <div class="mapText-cont-item">
                            <p>公司地址：</p>
                            <span>{{data.comadress}}</span>
                        </div>
                        <div class="mapText-cont-item">
                            <p>联系电话：</p>
                            <span>{{data.comtel}}</span>
                        </div>
                        <div class="mapText-cont-item">
                            <p>联系人：</p>
                            <span>{{data.linktel}}</span>
                        </div>
                        <div class="mapText-cont-item">
                            <p>公司邮箱：</p>
                            <span>{{data.comemail}}</span>
                        </div>
                    </div>
                    <div class="mapText-er">
                        <p>公众号：{{data.pubname}}</p>
                        <img :src="data.pubpic" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AMap from './../../components/map.vue'
import User from "./../../components/user.vue"
export default {
    components: {
        AMap,
        User
    },
    data(){
        return{
            data:{},
        }
    },
    created(){
        this.Postconfig()
    },
    methods:{
        // 配置信息
        Postconfig(){
            this.$api.config().then(res=>{
                this.data = res
            })
        },
    }
}
</script>
<style scoped>
.content {

    min-height: 100vh;
    background-color: #eee;

}

.header {
    width: 90%;
    margin: 0 auto;
    padding-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cont-box {
    width: 90%;
    min-width: 45em;
    margin: 0 auto;
    margin-top: 2em;
    padding: 2em;
    background-color: #fff;
    box-sizing: border-box;
}

.title {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title p{
    color: rgb(158, 158, 158);
}

.title-head {
    width: 100%;

    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
}

.title-header-item {
    width: 5em;
    border-top: 1px solid rgb(177, 175, 175);
}

.title-head h3 {
    font-size: 1.5em;
    padding: 0 1em;
}

.cont-box-p {
    width: 80%;
    margin: 0 auto;
    margin-top: 1em;
    text-align: center;
    line-height: 1.8em;
    color: rgb(158, 158, 158);
}

/* 地图/公司简介 */
.mapBox {
    margin-top: 4em;
    height: 20em;
    display: flex;
}
.mapText{
    margin-left: 4em;
    display: flex;
    flex-direction: column;

}
.mapText p{
    color: rgb(158, 158, 158);
    display: inline-block;
    line-height: 1.8em;
}
.mapText  span{
    line-height: 1.8em;
    color: rgb(158, 158, 158);
}
.mapText h4{
    font-size: 1.2em;
    margin-bottom: 1em;
}
.mapText-cont{
    margin-bottom: 1em;
}
.mapText-er{
    display: flex;
    flex-direction: column;
}
.mapText-er p{
    margin-bottom: 1em;
}
.mapText-er img{
    width: 6em;

}
</style>